.received_msgPreview {
  border-radius: 25px;
  border-bottom-left-radius: 0px;
  padding: 10px;

  /* Neutral / 00 */

  background: #ffffff;
  color: #000000;
}

.sent_msgPreview {
  border-radius: 25px;
  border-bottom-right-radius: 0px;
  padding: 10px;

  /* Neutral / 00 */

  background: #343434;
  color: #ffffff;
}
