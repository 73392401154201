
.pn-msg-list, .pn-msg-input{
  background:none !important;
}
.pn-msg-input__textarea, .pn-msg-input__textarea:focus{
  border: 1px solid #EEEFF2 !important;
  border-radius: 30px !important;
  padding: 15px !important;
  color: white !important;
  background-color: #171717 !important;
}

.pn-msg-input__fileLabel svg {
    fill: #FEF15D !important;
}

.pn-msg:hover{
    background-color: #171717 !important;
}