.customContainer {
    border-radius: 16px;
    border: 1px solid #EEEFF2;
    font-size: 16px;
    margin-top: 11px;
    min-height: 154px;
    padding: 5px
}

.ImageContainer {
    border-radius: 16px;
    border: 1px solid #EEEFF2;
    font-size: 16px;
    margin-top: 11px;
    height: 154px;
}

.customContainer .businessHourBtn {
    width: max-content;
    border-radius: 8px;
    padding: 5px 15px 5px 15px;
    border: 1px solid #EEEFF2;
    cursor: pointer
}