
.businessHour_timepicker{
    position: relative;
}
.businessHour_timepicker  input{
    width: 90px;
    padding: 0;
    border: none;
    margin: 0;
    color: white;
}

.businessHour_timepicker button{
    opacity: 0;
    z-index: 3;
}
.businessHour_timepicker_icon{
    position: absolute;
    left: 116px;
    top: 17px;
    z-index: 2;
}