body {
  background-color: #171717;
  color: #ffffff;
  font-family: 'Space Grotesk';
}

.primaryColor {
  color: #25d2ee;
}

.cursorPointer {
  cursor: pointer;
}

.routerLink {
  text-decoration: none;
  color: inherit;
}

.dashboardMainSection {
  /* min-height: 100vh; */
  background-color: #171717;
  color: #ffffff;
  width: 100vw;

  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.dashboardHeader {
  background-color: #171717 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.dashboardSidebar .MuiDrawer-paper {
  background: #343434;
  color: #ffffff;
}

.dashboardSidebar .sidebarNavLink {
  padding: 16px;
  border-left: 3px solid #343434;
}

.dashboardSidebar .sidebarNavLink:hover,
.dashboardSidebar .sidebarActiveNavLink {
  font-weight: bold;
  color: #3af2ff;
  border-left: 3px solid #3af2ff;
  cursor: pointer;
}

.inputChecked {
  color: #fef15d;
}
.inputUnChecked {
  color: '#616161';
}

input[type='time']::-webkit-calendar-picker-indicator {
  color: red !important; /* change the color of the icon */
}

input[type='time']::-webkit-calendar-picker-indicator::before {
  content: '\f017' !important; /* replace the icon with a Font Awesome icon */
  font-family: 'Font Awesome 5 Free';
  color: blue !important; /* change the color of the new icon */
  font-size: 16px;
  background: black;
}

.roundedCorner {
  border-radius: 15px;
}

.popoverMenuItem {
  text-align: center;
  padding: 5px;
}
.popoverMenuItem:hover,
.popoverMenuItem-active {
  background-color: black;
  border-radius: 10px;
}

.chip_rounded_border{
  border-radius: 50px !important;
}

.simple_chip{
border: 1px solid white  !important;
background-color: transparent !important;
color: white !important;
}


/* Scroll bar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #25D2EE; 
}

.input_disabled >
  input {
    background: gray !important;
    cursor: not-allowed;
  }
